@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "media";

html, body {
  font-family: 'Montserrat', sans-serif;
}

#root {
  //margin-bottom: 51px;
}
.wrapper {
  //max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  //padding-bottom: 1px;
}

/*=============== error ================*/

.invalid{
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #b9132f;
 }

/* ============== nav ============= */

nav {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  top: -100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  height: 50px;
  &.sticky {
    position: sticky;
    top: 0;
    z-index: 9;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
    -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .cart {
      margin-right: 15px;

      a {
        position: relative;
        display: inline-block;
        padding: 5px;
        line-height: 16px;
        font-size: 20px;

        span {
          position: absolute;
          right: -9px;
          top: -9px;
          width: 20px;
          height: 20px;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          background-color: #3597de;
          margin-left: 0;
          margin-right: 0;
        }

        &:hover {
          background: none !important;
        }
      }
    }


    a {
      display: inline-block;
      line-height: 64px;
      font-size: 14px;
      font-weight: 500;
      color: #898989;
      padding-left: 10px;
      padding-right: 10px;
    }

    &.logo {
      text-transform: uppercase;
    }

    &.menu {
      > li {
        position: relative;
      }

      span {
        font-size: 12px;
      }

      .profile {
        height: 50px;
        a {
          line-height: 50px;
        }
        img {
          margin-right: 8px;
        }
      }

      a {
        img {
          width: 30px;
        }

        &:hover {
          text-decoration: none;
          color: #898989;
          background-color: rgba(137, 137, 137, 0.1);
        }
      }

      .submenu {
        display: block;
        background-color: #fff;
        position: absolute;
        z-index: 2;
        min-width: 100%;
        opacity: 0;
        left: auto;
        right: 0;
        top: -999px;
        white-space: nowrap;
        -webkit-transition: 0.3s opacity;
        -moz-transition: 0.3s opacity;
        -ms-transition: 0.3s opacity;
        -o-transition: 0.3s opacity;
        transition: 0.3s opacity;

        li {
          width: 100%;
        }

        a {
          font-size: 12px;
          line-height: 18px;
          display: block;
          padding-bottom: 10px;
          padding-top: 10px;
          text-transform: capitalize;

          i {
            font-size: 18px;
            margin-right: 5px;
          }
        }

        &.visible {
          opacity: 1;
          top: 100%;
        }
      }

      @media #{$min991} {
        > li {
          &:hover {
            .submenu {
              opacity: 1;
              top: 100%;
            }
          }
        }
      }
    }

    &.logo {
      a {
        display: flex;
        align-items: flex-end;

        span {
          margin-left: 5px;
          line-height: 20px;
          margin-bottom: 8px;
          font-weight: 700;
          color: #e74c3c;
        }

        &:hover {
          text-decoration: none;
        }
      }

      img {
        width: 45px;

      }
    }
  }

  @media  #{$max575}{
    padding-left: 0;
    padding-right: 0;
    ul {
      &.logo {
        a span {
          display: none;
        }
      }
    }
  }
}

/* ============== tab name ============= */
.tabName {
  margin-top: 5px;
  font-size: 18px;
  padding-left: 5px;
  color: #898989;
  text-transform: capitalize;

  i {
    font-size: 28px;
    color: #949494;
    margin-right: 10px;
  }

  @media #{$max991} {
    display: none;
  }
}

/* ============== personal menu ============= */
.personalMenu {
  margin-top: 90px;

  ul {
    padding-top: 20px;
    padding-bottom: 20px;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: 0;
    //background-color: #f9f9f9;

    li {
      a {
        text-transform: capitalize;
        padding: 8px 15px;
        display: block;
        color: #656565;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;
        position: relative;

        i {
          margin-right: 10px;
        }

        &:hover {
          text-decoration: none;
          background-color: rgba(101, 101, 101, 0.25);
        }

        &.active {
          background-color: rgba(101, 101, 101, 0.05);

          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 3px;
            background-color: #656565;
          }

          &:hover {
            text-decoration: none;
            background-color: rgba(101, 101, 101, 0.25);
          }
        }
      }
    }
  }

  @media #{$max767} {
    margin-top: 0;
  }

  @media #{$min991} {
    position: sticky;
    top: 15px;
  }
}


/* ============== title ============= */
.title {
  background-color: #32b9da;
  color: #fff;
  padding: 44px 30px;
  position: relative;
  //min-height: 170px;

  h1 {
    text-align: center;
    font-size: 51px;
    line-height: 51px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;

    & + p {
      text-align: center;
      font-weight: 500;
    }
  }

  .image {
    bottom: -70px;
    position: absolute;
    z-index: 9;
    img {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 2px solid #32b9da;
      background-color: #32b9da;
      width: 140px;
      left: 0;
    }

    > span {
      display: inline-block;
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);
      margin-left: 10px;
      font-weight: 500;
      line-height: 20px;

      span {
        font-size: 12px;

      }
    }
  }

  @media #{$max767} {
    min-height: auto;
    padding: 20px;
    h1 {
      font-size: 30px;
      display: none;
    }
    .image {
      position: relative;
      bottom: auto;
      display: flex;
      align-items: center;

      > span {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
      }

      img {
        width: 70px;
      }
    }
  }
}

/* ============== main ============= */
//main {
//  margin-bottom: 40px;
//}

/* ============== footer ============= */
footer {
  width: 100%;
  background-color: #656565;
  font-size: 14px;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  p {
    margin-bottom: 0;
  }
}

.globalLoading{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  background-color: rgba(255, 255, 255, 0.5);
  &::after{
    content: '';
    position: absolute;
    width: 60px;
    height: 60px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: 4px solid;
    border-color: #fd6c00 transparent;
    left: 50%;
    top: 50%;
    -webkit-animation: 0.8s spin infinite;
    -o-animation: 0.8s spin infinite;
    animation: 0.8s spin infinite;
  }
}

@keyframes spin {
  from {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -moz-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    -o-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -moz-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    -o-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}