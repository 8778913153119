.forgotPasswordDiv {
  background-color: #efefef;
  .loginForm {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 15px;

    input {
      width: 100%;
      padding: 8px;
      font-size: 14px;
      border: 1px solid;
      height: auto;
    }

    button {
      cursor: pointer;
      margin-top: 20px;
      max-width: 220px;
      width: 100%;
      height: 60px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      border: none;
      background-color: rgba(253, 108, 0, 0.8);
    }
  }
}