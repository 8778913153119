@import "media";
//@import "main";

/* ============== title ============= */
.title {
  background-color: #32b9da;
  color: #fff;
  padding: 44px 30px;
  position: relative;
  //min-height: 170px;

  h1 {
    text-align: center;
    font-size: 51px;
    line-height: 51px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;

    & + p {
      text-align: center;
      font-weight: 500;
    }
  }

  @media #{$max767} {
    min-height: auto;
    padding: 20px;
    h1 {
      font-size: 30px;
      display: block;
    }
  }

}

/* ============== main ============= */
main.login {
  max-width: none;
  background-color: #efefef;

  .loginForm {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 15px;

    input:not([type="checkbox"]) {
      width: 100%;
      padding: 8px;
      font-size: 14px;
      border: 1px solid;
      height: auto;

      &[type="password"] {
        letter-spacing: 1px;
      }

      &:focus {
        outline: none;
      }
    }

    p {
      font-size: 12px;
      margin-bottom: 3px;
      font-weight: 500;

    }

    > div {
      margin-top: 20px;
    }

    .remember {
      //display: flex;
      justify-content: space-between;
      font-size: 14px;

      input {
        position: absolute;
        opacity: 0;
        pointer-events: none;

        &:not(checked) {
          & + label {
            &::after {
              content: '';
              position: absolute;
              width: 16px;
              height: 16px;
              border: 1px solid #32b9da;
              left: 0;
              top: 1px;
            }
          }
        }

        &:checked {
          & + label {
            &::before {
              font-family: FontAwesome;
              content: '\f00c';
              position: absolute;
              left: 1px;
              top: -1px;
            }
          }
        }
      }

      label {
        cursor: pointer;
        position: relative;
        //padding-left: 20px;
      }

      a {
        color: #000;

        i {
          margin-right: 5px;
        }
      }
    }

    button {
      cursor: pointer;
      margin-top: 20px;
      max-width: 220px;
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 700;
      color: #fff;
      border: none;
      background-color: rgba(253, 108, 0, .8);
      -webkit-transform: translateY(3px);
      -moz-transform: translateY(3px);
      -ms-transform: translateY(3px);
      -o-transform: translateY(3px);
      transform: translateY(3px);
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;
      transition: 0.3s all;

      &:hover {
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
        transform: translateY(0);
        -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
        -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
      }
    }

  }

  /* =================== register =================*/
  .registerForm {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 15px;

    .formGrid {
      margin-top: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 15px;

      > div {
        position: relative;
      }

      ul {
        font-weight: 500;
        position: absolute;
        margin-bottom: 0;
        font-size: 11px;
        list-style-type: none;
        padding-left: 0;
        text-align: center;
        width: 100%;
        color: #b9132f;
      }

    }

      input:not([type="checkbox"]) {
        width: 100%;
        padding: 8px;
        font-size: 14px;
        height: auto;

        &[type="password"] {
          letter-spacing: 1px;
        }

        &:focus {
          outline: none;
        }
      }

    .ant-col.ant-form-item-label{
      line-height: 20px;
    }

      label {
        font-size: 12px;
        margin-bottom: 3px;
        font-weight: 500;
        line-height: 15px;

      }



      .terms {
        margin-top: 15px;

        label {
          cursor: pointer;
          position: relative;
          padding-left: 22px;
          font-size: 14px;
          display: inline-block;
          width: 100%;


          a {
            text-decoration: underline;
            color: #32b9da;
            font-weight: 700;
          }
        }

        input {
          position: absolute;
          opacity: 0;
          pointer-events: none;

          &:not(checked) {
            & + label {
              &::after {
                content: '';
                position: absolute;
                width: 16px;
                height: 16px;
                border: 1px solid #32b9da;
                left: 0;
                top: 1px;
              }
            }
          }

          &:checked {
            & + label {
              &::before {
                font-family: FontAwesome;
                content: '\f00c';
                position: absolute;
                left: 1px;
                top: -1px;
              }
            }
          }
        }
      }

      .submit {
        cursor: pointer;
        margin-top: 20px;
        max-width: 220px;
        width: 100%;
        display: block;
        margin-left: auto;
        margin-right: auto;
        padding: 10px 0;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        border: none;
        background-color: rgba(253, 108, 0, .8);
        -webkit-transform: translateY(3px);
        -moz-transform: translateY(3px);
        -ms-transform: translateY(3px);
        -o-transform: translateY(3px);
        transform: translateY(3px);
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;

        &:hover {
          -webkit-transform: translateY(0);
          -moz-transform: translateY(0);
          -ms-transform: translateY(0);
          -o-transform: translateY(0);
          transform: translateY(0);
          -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
          -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
          box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
        }
      }


    @media #{$max575} {
        .formGrid {
          grid-template-columns: 1fr
        }
    }
  }

}


/* ============== modal ============= */
.termsModalWrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  z-index: 6;

  .termsModal {
    max-width: 630px;
    width: 100%;
    margin: 60px auto 20px;
    background-color: #fff;

    .termsHead {
      text-align: center;
      padding: 15px;
      border-bottom: 1px solid #efefef;
      position: relative;

      h3 {
        margin-bottom: 0;
      }

      button {
        top: 5px;
        right: 15px;
        background: none;
        border: none;
        position: absolute;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
    }

    .termsContent {
      padding: 15px;

      p {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 20px;
      }
    }

    .termsFooter {
      padding: 15px;
    }
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  @media #{$max767}{
    .termsModal {
      width: calc(100% - 30px);
      margin-top: 20px;

      .termsHead {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
}

