@import "media";
@import "variable";

/* ============== title ============= */

nav {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* ============== main ============= */

.settingsTab {
section {
  background-color: white;
}
  .navButtons {
    display: flex;
    justify-content: center;
    padding: 15px;

    > div {
      display: flex;

      button {
        padding: 10px;
        background-color: white;
        margin: 0 15px;
        border: $orange solid 1px;
        border-radius: 5px;
        color: $orange;
        height: 100%;
        font-size: 16px;

        i {
          margin-right: 5px;
        }

        &:hover, &:focus, &.active {
          color: white;
          background-color: $orange;
          border-color: white;
        }
        &.active {
          transform: translateY(18px);
          z-index: -1;
        }
      }
    }
  }

  .saveChanges {
    cursor: pointer;
    margin-top: 20px;
    max-width: 220px;
    width: 100%;
    display: block;
    margin-left: auto;
    padding: 12px 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    border: none;
    background-color: rgba(253, 108, 0, 0.8);
    -webkit-transform: translateY(3px);
    -moz-transform: translateY(3px);
    -ms-transform: translateY(3px);
    -o-transform: translateY(3px);
    transform: translateY(3px);
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;
    transition: 0.3s all;

  }

}
