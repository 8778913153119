@import "media";
@import "variable";
//@import "main";

body {
  background-color: #efece7 !important;
}

#root {
  height: 100%;
}

.wrapper {
  max-width: none;
  height: 100%;
  >div {
    //minus nav and footer heights
    min-height: calc(100% - 50px - 50px);
  }
  >main {
      min-height: calc(100% - 50px - 50px);
    }


}

nav {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

/* ============== header ============= */
header {
  text-align: center;
  position: relative;
  padding: 54px 104px 20px;
  z-index: 0;
  display: block;
  background: $greenBackground;
  color: #287058;

  .ribbon {
    width: 185px;
    height: 33px;
    line-height: 33px;
    margin: 0 auto 44px;
    position: relative;
    color: #fff;
    font-size: small;
    text-align: center;
    text-transform: uppercase;
    background: #e74c3c;

    span {
      font-size: 12px;
      color: #fff;
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    i {
      &:first-of-type, &:nth-of-type(2) {
        position: absolute;
        left: -13px;
        bottom: -13px;
        z-index: -1;
        border: 13px solid transparent;
        border-right-color: #7a1414;
        -webkit-animation: edge .5s;
        -moz-animation: edge .5s;
        -ms-animation: edge .5s;
        animation: edge .5s;
      }

      &:nth-of-type(2) {
        left: auto;
        right: -13px;
        border-right-color: transparent;
        border-left-color: #7a1414;
      }

      &:last-of-type, &:nth-of-type(3) {
        position: absolute;
        width: 44px;
        bottom: -13px;
        left: -31px;
        z-index: -2;
        border: 16px solid #b13f3f;
        border-left-color: transparent;
        -webkit-animation: back .6s;
        -moz-animation: back .6s;
        -ms-animation: back .6s;
        animation: back .6s;
        -webkit-transform-origin: 100% 0;
        -moz-transform-origin: 100% 0;
        -ms-transform-origin: 100% 0;
        transform-origin: 100% 0;
      }

      &:last-of-type {
        left: auto;
        right: -31px;
        border-left-color: #b13f3f;
        border-right-color: transparent;
        -webkit-transform-origin: 0 0;
        -moz-transform-origin: 0 0;
        -ms-transform-origin: 0 0;
        transform-origin: 0 0;
      }
    }
  }

  .headerTitle {
    max-width: 900px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    h3 {
      text-align: center;
      font-size: 40px;
      line-height: 50px;
      font-weight: 500;
    }

    p {
      font-size: 13px;
      position: relative;
      text-align: center;
      max-width: 329px;
      display: block;
      margin: 40px auto 0;
      background: $greenBackground;

      &::after {
        content: '';
        position: absolute;
        width: 660px;
        height: 1px;
        background: rgba(145, 123, 96, .32);
        left: -50%;
        top: 50%;
        z-index: -1;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
  }

  .webWindow {
    margin-top: 30px;
    max-width: 840px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    color: #e8edee;
    font-size: 14px;
    position: relative;

    img {
      width: 100%;
    }

    span {
      position: absolute;
      right: 25px;
      top: 25px;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;

      i {
        margin-right: 3px;
      }
    }
  }

  @media #{$max767} {
    padding-left: 15px;
    padding-right: 15px;
    .headerTitle {
      h3 {
        font-size: 20px;
        line-height: 34px;
      }

      p {
        &::after {
          content: none;
        }
      }
    }

  }
}

/* ============== main ============= */
main {
  max-width: 1200px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: #efece7;

  section {
    &.filter {
      padding-top: 40px;
      color: #656565;
      padding-bottom: 40px;

      h3 {
        text-align: center;
        font-size: 37px;
        line-height: 45px;
        font-weight: 500;
      }

      > p {
        text-align: center;
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        text-transform: uppercase;
      }

      .filterOption {
        margin-top: 20px;
        text-align: center;
        display: grid;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(150px, 250px));

        button {
          max-width: 100%;
          width: 100%;
          padding: 10px 15px;
          background-color: #fff;
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 500;
          border: 1px solid #c0c0c0;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          border-radius: 4px;
          cursor: pointer;
          -webkit-transition: 0.3s all;
          -moz-transition: 0.3s all;
          -ms-transition: 0.3s all;
          -o-transition: 0.3s all;
          transition: 0.3s all;
          -webkit-transform: translateY(3px);
          -moz-transform: translateY(3px);
          -ms-transform: translateY(3px);
          -o-transform: translateY(3px);
          transform: translateY(3px);

          &.active {
            background: #14507a;
            color: #fff;
          }

          &:hover {
            -webkit-transform: translateY(0);
            -moz-transform: translateY(0);
            -ms-transform: translateY(0);
            -o-transform: translateY(0);
            transform: translateY(0);
            -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
            -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
          }

          &:focus {
            outline: none;
          }
        }
      }
    }

    &.sslS {
      min-height: 300px;
      margin-top: 50px;
      padding-bottom: 10px;
      padding-left: 15px;
      padding-right: 15px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(auto-fill, minmax(289px, 1fr));
      font-weight: bold;
      .ssl {
        position: relative;
        background-color: $sslBackground;
        -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
        -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
        box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);

        > a {
          color: #656565;
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 3px;
          display: block;
          position: relative;


          &:hover {
            text-decoration: none;

            .sslHead {
              color: #fff;

              &::before {
                height: 100%;
              }
            }
          }
        }

        .sslHead {
          padding: 30px 30px 15px;
          background-color: #fff;
          position: relative;
          z-index: 2;

          &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 0;
            background-color: #14507a;
            left: 0;
            bottom: 0;
            z-index: -1;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            transition: 0.3s all;
          }

          .sslStar {
            width: 82px;
            height: 15px;
            background-image: url('../img/stars.png');
            background-repeat: no-repeat;

            &.star-5 {
              background-position-y: -90px;
            }

            &.star-45 {
              background-position-y: -75px;
            }

            &.star-4 {
              background-position-y: -60px;
            }

            &.star-35 {
              background-position-y: -45px;
            }

            &.star-3 {
              background-position-y: -30px;
            }
          }

          p {
            margin-bottom: 0;
            width: 100%;
            -ms-text-overflow: ellipsis;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }

          .promo {
            pointer-events: none;
            position: absolute;
            top: 50%;
            right: -10px;
            z-index: 1;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);

            > div {
              width: 62px;
              height: 33px;
              text-align: center;
              position: relative;

              &::after {
                content: "";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 0;
                position: absolute;
                bottom: -10px;
                right: 0;

              }

              &.promoSale {
                text-transform: uppercase;
                color: #fff;
                font-weight: 700;
                font-size: 12px;
                line-height: 33px;
                background-color: #ff8327;
                border-bottom: 1px solid #ff8327;
                bottom: 2px;

                &::after {
                  border-color: #fd6c00 transparent transparent;
                }
              }

              &.promoHot {
                margin-top: 2px;
                text-transform: uppercase;
                color: #fff;
                font-weight: 700;
                font-size: 12px;
                line-height: 33px;
                background-color: #ea4f3b;
                border-bottom: 1px solid #ea4f3b;

                &::after {
                  border-color: #cd2b16 transparent transparent;
                }
              }
            }
          }
        }

        .sslBody {
          padding: 20px 30px 70px;
          position: relative;
          background-color: $sslBackground;
          color: #156072;

          .sslPrice {
            font-size: 28px;
            line-height: 30px;
            font-weight: 700;

            sub, sup {
              font-size: 15px;
            }
          }

          .sslInfo {
            p {
              margin-bottom: 0;
              line-height: 30px;
              font-weight: 500;
            }
          }
        }
        .buyNow {
          margin-top: 15px;
          text-align: right;
          position: absolute;
          bottom: 20px;
          right: 20px;
          a {
            background: white;
            text-align: center;
            padding: 10px 20px;
            border-radius: 5px;
            color: $orange;
            border: $orange 1px solid;
            &:hover, &:focus {
              text-decoration: none;
              background-color: $orange;
              color: white;
            }
          }
        }
      }
    }
  }

  @media #{$max767} {
    section {
      &.filter {
        .filterOption {
          width: 100%;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 5px;

          button {
            font-size: 10px;
            font-weight: 700;

          }
        }
      }

      &.sslS {
        margin-top: 0;
      }
    }
  }
}