.csrFormSection {
  background-color: white;
  padding: 30px !important;

  .countryDropdown, .stateDropdown {
    height: 32px;
    width: 100%;
    border: 1px solid #d9d9d9;
    background: transparent;
    border-radius: 4px;
  }

  .buttonDiv {
    justify-content: center;
  }
  .keysDiv {
    .row {
      padding-top: 50px;
      justify-content: center;
      position: relative;
      i {
        position: absolute;
        right: 0;
        top: 55px;
        &:hover {
          cursor: pointer;
        }
        &.green {
          color: green;
        }
      }
    }
    textarea {
      height: 200px;
    }
  }
}