@import "media";
@import 'variable';

.checkout {
  .info {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    padding: 0;

    & > div {
      padding: 30px 0;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      > p {
        font-size: 40px;
      }

      &:first-of-type {
        background: $mainBlue;
      }

      &:nth-of-type(2) {
        background: rgb(78, 194, 223);
      }

      &:nth-of-type(3) {
        background: rgb(93, 199, 226);
      }

      &:nth-of-type(4) {
        background: rgb(107, 204, 228);
      }

      &:nth-of-type(5) {
        background: rgb(165, 224, 239);
      }

      &:nth-of-type(6) {
        background: rgb(150, 219, 236);
      }

      &:nth-of-type(7) {
        background: rgb(136, 214, 233);
      }

      &:last-of-type {
        background: rgb(121, 209, 231);
      }
    }

    @media #{$max575} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .checkoutDiv {
    background-color: #efece7;

    > .row {
      height: 100%;

      > div {
        > .leftDiv {
          position: sticky;
          top: 15px;
          height: 100%;
          background-color: $mainBlue;
          padding: 50px 20px;

          .ant-steps {
            .ant-steps-item-container {
              .ant-steps-item-icon {
                width: 29px;
                height: 29px;
                display: flex;
                justify-content: center;
                align-items: center;
              }

              .ant-steps-item-content {
                .ant-steps-item-title {
                  color: white;
                  font-weight: bold;
                }
              }
            }
          }

          .ant-steps-item-wait {
            .ant-steps-item-icon {
              background-color: white;
              border-color: $mainBlue;

              .ant-steps-icon {
                color: rgba(0, 0, 0, .25);
              }
            }
          }

          .ant-steps-item-active {
            .ant-steps-item-icon {
              border-color: $mainBlue;
              background-color: white;

              .ant-steps-icon {
                color: rgba(0, 0, 0, .25);
              }
            }
          }

          .ant-steps-item-finish {
            .ant-steps-item-icon {
              background-color: $orange;
              border-color: $orange;

              .ant-steps-icon {
                display: contents;
                color: white;
              }
            }

            .ant-steps-item-tail {
              &:after {
                background-color: $orange;
              }
            }
          }
        }

        > .rightDiv {
          padding-top: 35px;
          padding-bottom: 35px;
          height: 100%;

          > .container {
            box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
            background-color: white;
            border-radius: 5px;
            //border-bottom: 5px solid $mainBlue;
            .stepsDiv {
              background: $mainBlue;
              border-top-left-radius: 5px;
              border-top-right-radius: 5px;
              height: 60px;
              align-items: center;
              color: white;
              padding: 0 !important;

              p {
                font-weight: bold;
                margin: 0;
                font-size: 20px;
              }

              .leftDiv {
                display: flex;

                .arrowBtn {
                  cursor: pointer;
                  display: flex;
                  align-items: center;

                  &:not(:first-child) {
                    margin-left: 10px;
                  }

                  &:hover {
                    color: #c9f0ff;

                    i {
                      animation: shake 0.5s;
                      @keyframes shake {
                        20% {
                          transform: translate(3px)
                        }
                      }
                    }
                  }

                  i {
                    margin: 2px 5px 0 5px;
                    font-size: 12px;
                  }

                  &.selected {
                    font-weight: bold;
                    font-size: 17px;
                    &:hover {
                      color: white;
                      cursor: default;
                    }
                  }
                }
              }
            }

            > .row {
              padding: 30px;
            }

            .defaultBlueDiv {
              background: $mainBlue;
              display: flex;
              flex-direction: column;
              padding: 10px;
              border-radius: 7px;
              color: white;
            }

            .defaultBlueInput {
              border-radius: 7px;
              width: 100%;
              height: 45px;
              border: $mainBlue 1px solid;
              padding-left: 15px;

              &:focus {
                outline: none;
                box-shadow: 0 0 0 2px rgba(30, 160, 250, .2);
              }
            }

            .defaultOrangeDiv {
              cursor: pointer;
              margin-top: 20px;
              max-width: 220px;
              width: 100%;
              height: 60px;
              display: block;
              margin-left: auto;
              margin-right: auto;
              padding: 10px 0;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: 700;
              color: #fff;
              border: none;
              background-color: $orange;
            }
          }
        ;

          .productSectionDiv {
            .row:not(:last-child) {
              border-bottom: 1px solid $mainBlue;
              margin-left: 15px;
              margin-right: 15px;

              > div:first-child {
                p {
                  border-right: 1px dashed $mainBlue;
                }
              }

              p {
                margin: 0;

                &.keyName {
                  font-weight: bold;
                }
              }

              .ant-radio-group {
                width: 100%;

                .ant-radio-checked {
                  .ant-radio-inner {
                    border-color: $mainBlue !important;

                    &::after {
                      background-color: $mainBlue;
                      height: 7px;
                      width: 7px;
                    }
                  }
                }
              }

              .radioDiv {
                margin-bottom: 15px;

                label {
                  display: flex;
                  align-items: flex-start;

                  span {
                    font-size: 19px;
                    color: white;
                    font-weight: bold;

                    &:first-child {
                      margin-top: 5px;
                    }
                  }
                }

                .savingsDiv {
                  margin-left: auto;
                  text-align: right;
                  display: inline-block;
                  background: white;
                  padding: 5px;
                  //border: $orange solid 2px;
                  border-radius: 5px;
                  margin-top: 10px;
                  color: black;

                  p {
                    font-size: 12px;
                  }
                }
              }

              .nameDiv {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }

    .nextButton, {
      background-color: $orange;
      height: 35px;
      font-weight: bold;
      color: white;

      &:disabled {
        background-color: #fd6c0075;
      }

      &:focus, &:hover {
        border-color: #fd6c004d;
      }
    }

    .prevButton {
      height: 35px;
      font-weight: bold;

      &:focus, &:hover {
        border-color: $orange;
        color: $orange;
      }
    }

    .ant-form-explain {
      text-align: left;
      color: $orange;
    }
  }

  .ant-table-wrapper {
    .ant-table-thead {
      tr {
        th {
          font-weight: bold;
        }
      }
    }

    .ant-table-tbody {
      tr:last-child {
        font-weight: bold;
      }
    }
  }

  .addressDiv {
    form {
      margin: 0 !important;

      .credit-card-section {
        input {
          padding-left: 40px;
        }
      }
    }
  }

  .checkoutLogin {
    .row {
      position: relative;

      .leftDiv {
        border-right: 1px dashed;

        > div {
          margin: auto;
          max-width: 300px;
          text-align: center;
        }

        .passwordFormItem {
          margin: 0;
        }

        .login-form-forgot {
          float: right;
          color: #6c757d;
        }

        .remember {
          margin-top: 24px;

          .ant-checkbox {
            &:hover {
              .ant-checkbox-inner {
                border-color: $mainBlue;
              }
            }

            &.ant-checkbox-checked {
              .ant-checkbox-inner {
                background-color: $mainBlue;
              }
            }
          }
        }
      }

      .rightDiv {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .prevButtonDiv {
      display: flex;
      justify-content: center;
    }

    .centeredOrDiv {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: $mainBlue;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9;
      color: white;
    }
  }

  .productSectionDiv {
    .row {
      align-items: center;

      .col-lg-9 {
        justify-content: center;
      }

      &:last-child {
        justify-content: center;
        padding: 20px;
      }
    }
  }

  .csrForm {
    .ant-input-number {
      width: 100%;
    }

    .csrFormTitle {
      font-size: 18px;
      font-weight: bold;
    }

    .csrToken {
      .ant-form-item {
        margin: 0;
      }

      a {
        color: $mainBlue;
        font-size: 13px;
      }
    }

    .buttonsDiv {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid $mainBlue;
    }

    textarea {
      height: 100px;
    }
  }

  .paymentSectionDiv {
    padding: 30px;

    .paymentTitle {
      font-size: 18px;
      font-weight: bold;
    }
  }

  //ant input error color change
  .ant-form-item-control {
    &.has-error {
      input, textarea, .ant-input-number, .ant-select-selection {
        border-color: $orange;
      }
    }
  }

  .ant-form-item-label {
    label {
      margin: 0;
    }
  }
  .disabledSection {
    pointer-events: none;
    opacity: 0.6;
  }
}