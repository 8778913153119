/* ==========
    profile
   ========== */

@import "media";
//@import "main";

.title {
  background-color: #32b9da;
  color: #fff;
  padding: 44px 30px;
  position: relative;
  min-height: 170px;

  h1 {
    text-align: center;
    font-size: 51px;
    line-height: 51px;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: capitalize;

    & + p {
      text-align: center;
      font-weight: 500;
    }
  }

  .image {
    bottom: -70px;
    position: absolute;

    img {
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 2px solid #32b9da;
      background-color: #32b9da;
      width: 140px;
      left: 0;

    }

    span {
      line-height: 35px;
      display: inline-block;
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -ms-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      transform: translateY(-10px);
      //margin-left: 10px;
      font-weight: 500;
    }
  }

  @media #{$max767} {
    min-height: auto;
    padding: 20px;
    h1 {
      font-size: 30px;
    }
    .image {
      display: none;
    }
  }
}

.wrapper{
  max-width: none;
}

nav{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}


.info {
  padding-top: 15px;
  padding-bottom: 25px;
}


.personalInfo {
  background-color: #f9f9f9;
  padding: 15px;
}

.orders {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;

  .item {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 60px;
      margin-right: 5px;
    }

    > div {
      color: #656565;
      font-size: 14px;
    }

    span {
      display: block;
      text-align: center;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.personalTable {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  margin-bottom: 30px;

  > table:first-of-type {
    tr:nth-of-type(odd) {
      background-color: rgba(101, 101, 101, 0.05);
    }
  }

  > table:last-of-type {
    tr:nth-of-type(even) {
      background-color: rgba(101, 101, 101, 0.05);
    }
  }

  tr:first-of-type td {
    border-top: none;
  }

  tr:last-of-type td {
    border-bottom: none;
  }

  td {
    padding: 10px 5px;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
  }

  .name {
    width: 220px;
    font-size: 16px;
    font-weight: 500;
    color: #898989;
    max-width: 150px;
  }

  .text {
    font-size: 16px;
    font-weight: 500;
    max-width: 250px;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;

  }
}

@media #{$max767} {
  .orders {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 20px;

    .item {
      display: block;
      text-align: center;
      justify-content: flex-start;
    }
  }
  .personalTable {
    width: 100%;
    display: block;
    overflow: auto;

    table {
      width: 100%;
      min-width: 450px;
    }

  }
}