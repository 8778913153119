@import "variable";
.addNewCard {
  .row {
    margin: 0 !important;
  }
  h5 {
    padding-top: 35px;
    padding-bottom: 25px;
  }
  .GuestInfo {
    //padding-right: 50px;
    max-width: 579px;
  }

  .GuestInputs {
    > div {
      display: flex;
    }

    .SettingsUpdateButton {
      display: flex;
      margin-top: 20px;
      //padding-right: 52px;
      justify-content: flex-end;

      button {
        max-width: 158px;
        width: 100%;
        background: $orange;
        border: 1px solid $orange;
        border-radius: 8px;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
        font-weight: 600;
        display: block;
        //margin: 0 auto;
        padding-top: 7px;
        padding-bottom: 7px;
        height: auto;
        -webkit-transition: 0.3s all;
        -moz-transition: 0.3s all;
        -ms-transition: 0.3s all;
        -o-transition: 0.3s all;
        transition: 0.3s all;

        &:hover {
          background: #fff;
          color: $orange;
        }
      }
    }

    &.after {
      position: relative;
      padding-bottom: 24px;

      &::after {
        content: '';
        position: absolute;
        width: calc(100% + 52px);
        max-width: 632px;
        height: 1px;
        background-color: #DDDDDD;
        left: 0;
        bottom: 0;
      }
    }
  }

  .GlobalInput {
    p {
      font-size: 12px;
      color: #2E2E2E;
      font-weight: 700;
      line-height: 15px;
      margin-bottom: 6px;
    }

    .req:after {
      content: "*" !important;
      color: $orange;
      display: inline !important;
      margin-left: 3px;
    }

    input, textarea {
      margin-bottom: 16px;
      width: 100%;
      border: 1px solid #DDDDDD;
      border-radius: 6px;
      font-size: 12px;
      color: #2E2E2E;
      line-height: 15px;
      padding: 12px 15px;

      &::placeholder {
        color: #959595;
      }

      &:focus {
        outline: none;
      }
    }

    textarea {
      min-height: 70px;
      resize: none;
      margin-bottom: 27px;
    }
  }

  .credit-card-section {
    position: relative;

    img {
      position: absolute;
      height: 17px;
      top: 50px;
      left: 7px;
      z-index: 9;
    }
    
    input {
      padding-left: 40px;
    }
  }
  .ant-input-number {
    width: 100%;
  }
}