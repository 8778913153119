@import "media";
//@import "main";

/* ============== title ============= */
.title {
  background-color: #32b9da;
  color: #fff;
  padding: 44px 30px;
  position: relative;
  min-height: 170px;

  h1 {
    text-align: center;
    font-size: 51px;
    line-height: 51px;
    font-weight: 500;
    color: #fff;

    & + p {
      text-align: center;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  @media #{$max767} {
    min-height: auto;
    padding: 20px;
    h1 {
      font-size: 30px;
    }
  }
}

/* ============== main ============= */
main.products {
  background-color: #fff;
  max-width: none;
  .info {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    & > div {
      padding: 38px 0;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      font-size: 12px;
      font-weight: 700;

      > p {
        font-size: 40px;
      }

      &:first-of-type {
        background: rgba(50, 185, 218, .93);
      }

      &:nth-of-type(2) {
        background: rgb(78, 194, 223);
      }

      &:nth-of-type(3) {
        background: rgb(93, 199, 226);
      }

      &:nth-of-type(4) {
        background: rgb(107, 204, 228);
      }

      &:nth-of-type(5) {
        background: rgb(165, 224, 239);
      }

      &:nth-of-type(6) {
        background: rgb(150, 219, 236);
      }

      &:nth-of-type(7) {
        background: rgb(136, 214, 233);
      }

      &:last-of-type {
        background: rgb(121, 209, 231);
      }
    }

    @media #{$max575} {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .infoCart {

    padding: 44px;
    background: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    //align-items: center;

    .domain {
      display: flex;
      justify-content: space-between;

      .price {
        font-size: 28px;
        line-height: 30px;
        font-weight: 700;

        sub, sup {
          font-weight: 400;
          font-size: 15px;
        }
      }

      .year {
        position: relative;

        select {
          border: 2px solid #32b9da;
          padding: 6px 20px;
          background-color: transparent;
          font-weight: 500;
          &:focus{
            outline: none;
          }

          option {
            font-weight: 500;
          }
        }
      }
    }

    .addCart {
      margin-top: 25px;

      button {
        max-width: 220px;
        width: 100%;
        cursor: pointer;
        padding: 10px 0;
        margin: 0 auto;
        display: block;
        background: #fd6c00;
        border: none;
        text-transform: uppercase;
        color: #fff;
        border-radius: 2px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        -moz-box-shadow: 0 1px 1px rgba(9, 9, 9, .25);
        -webkit-box-shadow: 0 1px 1px rgba(9, 9, 9, .25);
        box-shadow: 0 1px 1px rgba(9, 9, 9, .25);
        -webkit-transition: 0.3s background-color;
        -moz-transition: 0.3s background-color;
        -ms-transition: 0.3s background-color;
        -o-transition: 0.3s background-color;
        transition: 0.3s background-color;
        &:hover{
          background-color: #32b9da;
        }
      }
    }

    img {
      display: block;
      margin: 30px auto 0;
      width: 218px;
    }
  }

  .tabs {
    //background-color: #fff;
    margin-bottom: 40px;

    .myTab{
      .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-card-bar{
        //background-color: #fff;
        margin-bottom: 0;
      }
      .ant-tabs-content.ant-tabs-content-no-animated.ant-tabs-top-content.ant-tabs-card-content{
        padding-top: 20px;
        background-color: #fff;
      }
    }

    a {
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: #32b9da;
    }

      margin-top: 30px;

      p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }

      table {
        width: 100%;

        tr:first-of-type {
          td {
            border-top: none;
          }
        }

        td {
          padding: 10px 5px;
          border-bottom: 1px solid #eaeaea;
          border-top: 1px solid #eaeaea;
        }

        .name {
          width: 220px;
          font-size: 16px;
          font-weight: 500;
          color: #898989
        }

        .text {
          font-size: 16px;
          font-weight: 500;
        }
      }
  }
}




