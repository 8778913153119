@import "media";
//@import "main";

.wrapper{
  max-width: none;
}

nav{
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

/* ============== main ============= */

  .info {
    padding-top: 0px;
    padding-bottom: 25px;
  }

  .personalInfo {
    background-color: #f9f9f9;
    padding: 15px;
  }

  .personalTable {
    margin-top: 50px;
    margin-bottom: 30px;

    table {
      width: 100%;
    }

    tr:nth-of-type(odd) {
      background-color: rgba(101, 101, 101, 0.05);
    }

    tr:first-of-type td {
      border-top: none;
    }

    tr:last-of-type td {
      border-bottom: none;
    }

    thead {
      background-color: rgba(101, 101, 101, 0.36);

      th {
        padding: 10px;
        color: #212529 !important;
        text-align: center;
        border: 1px solid #ffff;
      }
    }

    td {
      padding: 10px 5px;
      border: 1px solid #eaeaea;
      text-align: center;

      a {
        color: #656565;
        font-size: 12px;
        border: 1px solid #656565;
        display: inline-block;
        padding: 3px 8px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;

        &:hover {
          text-decoration: none;

          i {
            color: #32b9da;
          }
        }
      }

    }

    .name {
      width: 320px;
      font-size: 16px;
      font-weight: 500;
      color: #656565;
      text-align: left;
      padding-left: 15px;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      p {
        margin-bottom: 0;
        font-size: 12px;
        color: #949494;
      }

      div {
        font-size: 12px;

        span {
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          padding: 3px 5px;
          color: #fff;
          font-size: 11px;

          &.month {
            background-color: #949494;
          }

          &.serCode {
            background-color: #32b9da;
          }

          &.status {
            background-color: #ff8327;
          }
        }
      }
    }

    .text {
      font-size: 16px;
      font-weight: 500;
      color: #656565;
    }

    .exp {
      font-size: 14px;

      span {
        color: #3EA884;
        font-weight: 500;
      }
    }

    .issued {
      color: #3EA884;
      font-weight: 700;
      font-size: 12px;
    }

    .nComplete {
      color: #deac00;
      font-weight: 700;
      font-size: 12px;
    }

    .expired {
      color: #A83E3E;
      font-weight: 700;
      font-size: 12px;
    }

  }

  @media #{$max767} {
    .orders {
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 20px;

      .item {
        display: block;
        text-align: center;
        justify-content: flex-start;
      }
    }
    .personalTable {
      width: 100%;
      display: block;
      overflow: auto;

      table {
        width: 100%;
        min-width: 700px;
      }

    }
  }

  @media #{$max991} {

    .personalTable {
      margin-top: 0;
    }
  }
